@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Schabo-Condensed';
  src: local('Schabo-Condensed'),
    url(./skin/fonts/SCHABO-Condensed.otf) format('opentype');
}

@font-face {
  font-family: 'AdventPro-Regular';
  src: local('AdventPro-Regular'),
    url(./skin/fonts/AdventPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'AdventPro-SemiBold';
  src: local('AdventPro-SemiBold'),
    url(./skin/fonts/AdventPro-SemiBold.ttf) format('truetype');
}

