@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
    @responsive {
        .text-shadow {
          text-shadow: 0 2px 4px rgba(0,0,0,0.70);
          border-shadow: 0 2px 4px rgba(0,0,0,0.70);
        }
    }
}

@layer utilities {
  .fontPrimary {
    font-family: 'AdventPro-Regular';

  }

  .fontSecondary {
    font-family: 'Schabo-Condensed';
  }

  .font-bold {
    font-family: 'AdventPro-SemiBold';
  }
  .font-semibold {
    font-family: 'AdventPro-SemiBold';
  }



  .button-primary {
    @apply  btn btn-primary text-white text-2xl uppercase border-2 border-primary fontSecondary rounded-full bg-primary hover:bg-transparent hover:text-white hover:border-white;
  }
  .button-secondary {
    @apply  btn btn-primary text-white uppercase border-2 border-primary fontSecondary rounded-full bg-primary hover:bg-transparent hover:text-black hover:border-black;
  }
  .button-accept {
    @apply  btn btn-primary text-white bg-green-500 uppercase border-2 border-green-500 fontSecondary rounded-full hover:bg-transparent hover:text-green-500 hover:border-green-500;
  }
  .button-decline {
    @apply  btn btn-primary text-white bg-red-500 uppercase border-2 border-red-500 fontSecondary rounded-full hover:bg-transparent hover:text-red-500 hover:border-red-500;
  }
  .button-warn {
    @apply  btn btn-primary text-white bg-yellow-500 uppercase border-2 border-yellow-500 fontSecondary rounded-full hover:bg-transparent hover:text-yellow-500 hover:border-yellow-500;
  }
  .button-neutral {
    @apply  btn btn-primary text-white bg-blue-500 uppercase border-2 border-blue-500 fontSecondary rounded-full hover:bg-transparent hover:text-blue-500 hover:border-blue-500;
  }
 
 .btn {
 @apply fontPrimary px-6
 }

}
