h1, h2, h3 {
  font-family: 'Schabo-Condensed', sans-serif;
}

p {
  font-family: 'AdventPro-Regular', sans-serif;
}

.hero-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.hero-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  object-fit: cover;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-content {
  z-index: 10;
  text-align: center;
  max-width: 80%;
}

@media (max-width: 768px) {
  .hero-content {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .hero-content img {
    width: 80%;
    max-width: 200px;
  }
  
  .hero-content p {
    font-size: 1.5rem;
  }
}
